import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Box, Card, CardContent, Container, Divider, Link, Typography } from '@material-ui/core'
import { LoginJWT } from '../../components/authentication/login'
import Logo from '../../components/Logo';
import { useTranslation } from 'react-i18next';

const Login = () => {

  const {t} = useTranslation('login')

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    {t('sectionTitle')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {t('sectionDescription')}
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto'
                    }
                  }}
                >
                  <Logo
                    sx={{
                      height: 40,
                      width: 40
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                <LoginJWT />
              </Box>
              <Divider sx={{ my: 3 }} />
              <Link
                color="textSecondary"
                component={RouterLink}
                sx={{ mt: 1 }}
                to="/authentication/password-recovery"
                variant="body2"
              >
                {t('forgotPassword')}
              </Link>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
