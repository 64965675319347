import { createContext, useReducer, useEffect } from 'react'

import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
// import am4themes from '@amcharts/amcharts4/themes/frozen'
import am4themes from '@amcharts/amcharts4/themes/material'
// import am4themes from '@amcharts/amcharts4/themes/kelly'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

am4core.addLicense('CH281391603') // am4chart
am4core.addLicense('MP281391603') // am4maps
am4core.addLicense('TL281391603') // am4timeline

const initialChartsState = {
  chartsCore: null,
  amCharts: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { chartsCore, amCharts } = action.payload

      return {
        ...state,
        chartsCore,
        amCharts
      }
    }
    default: {
      return { ...state }
    }
  }
}

const ChartsContext = createContext({
  ...initialChartsState
})

export const ChartsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialChartsState)

  useEffect(() => {
    const initialise = () => {
      const chartsCore = am4core
      const amCharts = am4charts

      // Abilito l'auto-dispose per i grafici che non eseguono il dispose correttamente e rimangono 'appesi'
      chartsCore.options.autoDispose = true

      chartsCore.useTheme(am4themes)
      chartsCore.useTheme(am4themesAnimated)
      dispatch({
        type: 'INITIALISE',
        payload: {
          chartsCore,
          amCharts
        }
      })
    }

    initialise()
  }, [])

  return (
    <ChartsContext.Provider
      value={{
        ...state
      }}
    >
      {children}
    </ChartsContext.Provider>
  )
}

export default ChartsContext
