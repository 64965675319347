import { useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, /* Divider, */ Drawer } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useAuth from '@/hooks/useAuth'
import BriefcaseIcon from '@/icons/Briefcase'
import HomeIcon from '@/icons/Home'
import ShoppingCartIcon from '@/icons/ShoppingCart'
import StoreIcon from '@/icons/Store'
import BuildingIcon from '@/icons/Building'
import ReceiptIcon from '@material-ui/icons/Receipt'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Logo from '@/components/Logo'
import NavSection from '@/components/NavSection'
import Scrollbar from '@/components/Scrollbar'
import { useTranslation } from 'react-i18next'
import QrCodeIcon from '@material-ui/icons/QrCode2'
// import AccountConnectionSection from './AccountConnectionSection'

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user, selectedOrganization } = useAuth();

  const { t } = useTranslation('sidebar')
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const sections = [
    {
      items: [
        {
          title: t('organizations'),
          path: '/dashboard',
          icon: <BriefcaseIcon fontSize="small" />
        }
      ]
    },
    {
      title: t('home'),
      items: [
        {
          title: t('dashboard'),
          path: '/dashboard/home',
          icon: <HomeIcon fontSize="small" />
        }
      ]
    },
    {
      title: t('resources'),
      items: [
        {
          title: t('companies'),
          path: '/dashboard/companies',
          icon: <StoreIcon fontSize="small" />
        },
        {
          title: t('facilities'),
          path: '/dashboard/facilities',
          icon: <BuildingIcon fontSize="small" />
        },
        {
          title: t('products'),
          icon: <ShoppingCartIcon fontSize="small" />,
          path: '/dashboard/products',
        },
        {
          title: t('events'),
          path: '/dashboard/events',
          icon: <ReceiptIcon fontSize="small" />,
        },
        {
          title: t('lots'),
          path: '/dashboard/lots',
          icon: <QrCodeIcon fontSize="small" />,
        }
      ]
    },
    {
      title: t('features'),
      items: [
        {
          title: t('supplyChains'),
          path: '/dashboard/supplyChain/explore',
          icon: <LocalShippingIcon fontSize="small" />
        },
        {
          title: t('trackAndTrace'),
          icon: <LocationOnIcon fontSize='small' />,
          disabled: true
        }
      ]
    }
  ];

  const { organizations } = user

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar sx={{ height: '100%' }} options={{ suppressScrollX: true }}>
        <Box height='100%' display='flex' flexDirection='column' justifyContent='space-between'>
          <Box>
            <Box
              sx={{
                display: {
                  lg: 'none',
                  xs: 'flex'
                },
                justifyContent: 'center',
                p: 2
              }}
            >
              <RouterLink to="/">
                <Logo
                  sx={{
                    height: 40,
                    width: 40
                  }}
                />
              </RouterLink>
            </Box>
            <Box sx={{ p: 2 }}>
              {organizations?.length > 0 &&
                selectedOrganization
                ? sections.map((section, index) => (
                  <NavSection
                    key={section.title || `section-${index}`}
                    pathname={location.pathname}
                    sx={{
                      '& + &': {
                        mt: 3
                      }
                    }}
                    {...section}
                  />
                ))
                : sections.filter(section => section.items.find(el => el.path === '/dashboard')).map((section, index) => (
                  <NavSection
                    key={section.title || `section-${index}`}
                    pathname={location.pathname}
                    sx={{
                      '& + &': {
                        mt: 3
                      }
                    }}
                    {...section}
                  />
                ))}
            </Box>
          </Box>
          {/* <Box>
            <Divider />
            <AccountConnectionSection />
          </Box> */}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
