import { createContext, useEffect, useReducer } from 'react';
import api from '@micmnt/apis'
import { configuration } from '../config';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  selectedOrganization: null,
  availableFeatures: []
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, availableFeatures, selectedOrganization } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      availableFeatures,
      selectedOrganization
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user
    }
  },
  SET_AVAILABLE_FEATURES: (state, action) => {
    const { availableFeatures } = action.payload
    return {
      ...state,
      availableFeatures
    }
  },
  SET_ORGANIZATION: (state, action) => {
    const { selectedOrganization } = action.payload

    return {
      ...state,
      selectedOrganization
    }
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

const setSession = (accessToken) => {
  if (accessToken) {
    window.localStorage.setItem(configuration.accessTokenName, accessToken)
  } else {
    window.localStorage.removeItem(configuration.accessTokenName)
  }
}


export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  // Funzione che prende in ingresso l'account e l'organizzazione attuale e ricava le feature disponibili
  const getAvailableFeatures = (account, organizationId) => {
    if (organizationId) {
      const { organizations } = account || {}
      const currentOrganization = organizations.find(organization => organization.uuid === organizationId)
      if (currentOrganization) {
        return currentOrganization.functionality || []
      }
    }

    return []
  }

  // funzione che fa il setup delle info utente in seguito all'autenticazione tramite token
  const setupUserWithAuthToken = async (token) => {
    setSession(token)

    const { data: account, error } = await api.get({ path: `${configuration.apiUrl}/accounts/self` })

    if (error) {
      console.error(error)
      return dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      })
    }

    return { account }
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem(configuration.accessTokenName)

        const organization = window.localStorage.getItem('trusty-org')
        // setCurrentOrganization(organization)

        if (accessToken) {
          const { account } = await setupUserWithAuthToken(accessToken)
          const features = getAvailableFeatures(account, organization)

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: account,
              availableFeatures: features,
              selectedOrganization: organization
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              availableFeatures: [],
              selectedOrganization: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            availableFeatures: [],
            selectedOrganization: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const { data: response, error } = await api.post({ path: `${configuration.apiUrl}/public/accounts/authenticateTraceability`, body: { email, password, domain: 'trusty' } })

    if (error) {
      await logout()
    }

    const { token, user } = response

    setSession(token)
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    })
  }



  const logout = async () => {
    localStorage.removeItem(configuration.accessTokenName)
    dispatch({ type: 'LOGOUT' })
  };

  const setCurrentOrganization = (organization) => {
    if (!organization) {
      window.localStorage.removeItem('trusty-org')
    } else {
      window.localStorage.setItem('trusty-org', organization)
    }
    dispatch({
      type: 'SET_ORGANIZATION',
      payload: {
        selectedOrganization: organization
      }
    })
  }

  const setAvailableFeatures = (features) => {
    dispatch({
      type: 'SET_AVAILABLE_FEATURES',
      payload: {
        availableFeatures: features
      }
    })
  }

  const register = async (email, name, password) => {
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken);

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
        setCurrentOrganization,
        setAvailableFeatures
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
