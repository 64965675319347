export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
}

export const JWT_TOKEN_NAME = 'trusty-traceability-access-token'
// export const DEFAULT_API_AUTH_URL = 'https://traceabilityapi.trusty.id/v1'
export const DEFAULT_API_URL = 'https://traceabilityapi.trusty.id/v1'
export const DEFAULT_DISCOVER_PAGE = 'https://discover.trusty.id'
