import { useRoutes } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import api from '@micmnt/apis'
import './i18n'
import RTL from './components/RTL'
import SplashScreen from './components/SplashScreen'
import useAuth from './hooks/useAuth'
import useScrollReset from './hooks/useScrollReset'
import useSettings from './hooks/useSettings'
import routes from './routes'
import { createCustomTheme } from './theme'
import { apiConfig } from './api/config'
import { configuration } from './config'
import { ChartsProvider } from './contexts/ChartsContext'

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  useScrollReset();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  // Inizializzazione del servizio di chiamate API
  api.init({
    baseUrl: configuration.apiUrl,
    savedUrls: apiConfig,
    jwtTokenName: configuration.accessTokenName
  })


  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <ChartsProvider>
          <CssBaseline />
          <Toaster position="top-left" />
          {auth.isInitialized ? content : <SplashScreen />}
        </ChartsProvider>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
