import { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import AuthGuard from './components/AuthGuard'
import DashboardLayout from './components/dashboard/DashboardLayout'
import GuestGuard from './components/GuestGuard'
import LoadingScreen from './components/LoadingScreen'
import MainLayout from './components/MainLayout'

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
)

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')))
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')))
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')))
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')))

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')))
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')))
const Home = Loadable(lazy(() => import('./pages/dashboard/Home')))
const Companies = Loadable(lazy(() => import('./pages/dashboard/Companies')))
const Facilities = Loadable(lazy(() => import('./pages/dashboard/Facilities')))
const Products = Loadable(lazy(() => import('./pages/dashboard/Products')))
const Events = Loadable(lazy(() => import('./pages/dashboard/Events')))
const Lots = Loadable(lazy(() => import('./pages/dashboard/Lots')))
const SupplyChainExplore = Loadable(lazy(() => import('./pages/dashboard/SupplyChainExplore')))

// Error pages

const NotFound = Loadable(lazy(() => import('./pages/NotFound')))
const ServerError = Loadable(lazy(() => import('./pages/ServerError')))

const routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/dashboard',
        element: <Overview />
      },
      {
        path: '/dashboard/home',
        element: <Home />
      },
      {
        path: '/dashboard/account',
        element: <Account />
      },
      {
        path: '/dashboard/companies',
        element: <Companies />
      },
      {
        path: '/dashboard/facilities',
        element: <Facilities />
      },
      {
        path: '/dashboard/products',
        element: <Products />
      },
      {
        path: '/dashboard/events',
        element: <Events />
      },
      {
        path: '/dashboard/lots',
        element: <Lots />
      },
      {
        path: '/dashboard/lots/:lotNumber',
        element: <Lots />
      },
      {
        path: '/dashboard/lots/*', // Questa rotta è necessaria per gestire eventuali errori di lotNumber malformati
        element: <Lots />
      },
      {
        path: '/dashboard/supplyChain/explore',
        element: <SupplyChainExplore />
      },
      {
        path: '/dashboard/supplyChain',
        element: <Navigate to='/dashboard/supplyChain/explore' replace />
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/*',
        element: (
          <Navigate
            to='/dashboard'
            replace
          />
        )
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
]

export default routes
