import { experimentalStyled } from '@material-ui/core/styles';

const WHITE_COLOR = '#ffffff'
const BLACK_COLOR = '#000000'
const DEFAULT_COLOR = '#3C4858'

const LogoRoot = experimentalStyled('svg')``;

const isWhite = color => color === 'white' || color === '#fff' || color === '#ffffff'
const isBlack = color => color === 'black' || color === '#000' || color === '#000000'

const getFillColor = (color) => isWhite(color) ? WHITE_COLOR : isBlack(color) ? BLACK_COLOR : DEFAULT_COLOR

const Logo = ({ color = null, ...rest }) => (
  <LogoRoot
    width="150" height="144" viewBox="0 0 150 144" fill="none" xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>Trusty</title>
    <g fill="none" stroke="none">
      <g>
        <rect
          height="52"
          width="52"
          x="0"
          y="4.26325641e-14"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M66.3744 1.11506C61.0874 4.14706 61.0404 5.80405 64.7594 57.8381C66.4024 80.8181 66.8844 84.4411 68.4524 85.5881C71.0414 87.4811 73.6394 87.2141 74.8614 84.9311C76.4544 81.9551 77.8304 56.3671 77.8534 29.3031L77.8744 5.19905L75.4224 2.55306C72.8164 -0.258945 69.6474 -0.762945 66.3744 1.11506ZM107.189 9.91405C105.988 10.3981 104.571 11.6071 104.042 12.6011C103.023 14.5111 88.8744 74.9021 88.8744 77.3381C88.8744 79.5021 91.7364 81.9071 94.3124 81.9071C96.1904 81.9071 97.1794 80.6261 99.7614 74.8521C104.576 64.0831 118.874 21.0791 118.874 17.3661C118.874 14.9301 118.082 13.4181 115.797 11.4961C112.571 8.78106 110.806 8.45705 107.189 9.91405ZM22.0924 18.1751C20.8724 19.4731 19.8744 21.7831 19.8744 23.3061C19.8744 24.8301 24.4194 37.6261 29.9744 51.7421C35.5304 65.8581 40.9994 79.8821 42.1294 82.9071C45.3144 91.4361 46.6644 93.0611 50.2734 92.7091C53.0264 92.4411 53.4074 92.0141 53.6654 88.9071C54.1854 82.6521 38.2524 25.2561 34.4064 19.5301C31.5664 15.3011 25.4264 14.6261 22.0924 18.1751ZM0.81237 50.4821C-0.24463 51.7551 -0.26263 53.1791 0.71437 57.9131C3.54137 71.5981 22.3504 122.292 28.2484 132.12C32.8684 139.819 38.0394 143.043 46.7734 143.668C59.3514 144.568 59.8984 144.284 85.5024 123.563C98.6024 112.962 103.469 109.696 113.374 104.859C120.797 101.234 127.853 98.5461 131.874 97.8121C143.117 95.7561 145.809 94.8931 147.87 92.6811C150.562 89.7911 150.396 86.3381 147.419 83.3621C144.204 80.1461 138.297 80.1261 122.874 83.2791C108.681 86.1821 102.779 88.5071 76.8744 101.405C65.5994 107.018 55.1414 111.91 53.6334 112.276C49.2954 113.328 42.4384 111.098 39.1464 107.565C37.5284 105.828 30.4504 93.3821 23.4184 79.9071C16.3864 66.4321 9.53937 53.9441 8.20237 52.1571C5.55937 48.6211 2.86337 48.0101 0.81237 50.4821Z" fill={getFillColor(color)} />
      </g>
    </g>
  </LogoRoot>
);

export default Logo;
