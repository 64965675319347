import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  notifications: []
}

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    getNotifications (state, action) {
      const notifications = action.payload

      state.notifications = notifications
    }
  }
})

export const { reducer } = slice

export const getNotifications = () => (dispatch) => {
  const data = []

  dispatch(slice.actions.getNotifications(data))
}

export default slice
