export const apiConfig = {
  accounts: '/accounts',
  companies: '/companies',
  facilities: '/facilities',
  products: '/products',
  events: '/events',
  lots: '/marketing/aggregateLots',
  lotEvents: '/events/traceBackward/lot',
  lotsLabel: '/lots',
  supplyChains: '/supplychains',
  smlInstances: '/smlInstances',
  organizations: '/organizations',
  openstreetmap: 'https://nominatim.openstreetmap.org'
}
